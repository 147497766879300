<template>
    <section v-if="!_.isEmpty(cedis)">
        <div class="custom-scroll overflow-auto px-3" style="height:calc(100vh - 195px)">
            <div class="row mx-0 my-3">
                <div class="col-5 d-middle px-0">
                    <i class="icon-format-list-checks f-20 text-general" />
                    <span class="text-general f-16 f-600 ml-2">
                        Cedis
                    </span>
                </div>
                <div class="col pl-4">
                    <span class="text-general f-16 f-600 ml-2">
                        Precio
                    </span>
                </div>
            </div>
            <div v-for="(c,index) in cedis.data" :key="index" class="row mx-0 pl-2 mb-3">
                <div class="col-5 px-0 pt-4">
                    <el-checkbox v-model="c.estado" :disabled="activos && (c.estado)" class="check-dark" @change="actualizarGamingProductoCedis(c.id,1,c.estado)" />
                    <span class="text-general f-16 ml-3">{{ c.nombre }}</span>
                </div>
                <div class="col pr-0 ml-3">
                    <div class="row mx-0 align-items-end">
                        <img src="/img/gaming/i_moneda.svg" height="30" />
                        <div class="col">
                            <p class="label-mini">
                                Monedas
                            </p>
                            <el-input v-model="c.monedas" class="w-100" size="small" @change="actualizarGamingProductoCedis(c.id,2,c.monedas)" />
                        </div>
                    </div>
                    <p class="text-center f-12 text-general pl-4 my-2">
                        0
                    </p>
                    <div class="row mx-0 align-items-end">
                        <img src="/img/gaming/i_gema.svg" height="30" class="mb-3" />
                        <div class="col">
                            <p class="label-mini">
                                Gemas
                            </p>
                            <el-input v-model="c.gemas" class="w-100" size="small" @change="actualizarGamingProductoCedis(c.id,3,c.gemas)" />
                            <p class="text-center f-12 text-white">
                                0
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section v-else class="col pr-0 px-0 mx-0 my-auto h-90">
        <div class="row mx-0 justify-center align-content-center h-100 f-60">
            <div class="col-5 text-center">
                <img src="/img/no-imagen/imgDefault.svg" style="width: 115px; height: 115px;" />
                <p class="mb-0 text-muted2 mt-2 f-17 f-600">Selecciona un producto del panel de la izquierda.</p>
            </div> 
        </div>
    </section>
</template>

<script>
import GamingProductos from '@/services/gaming/gamingProductos'
export default {
    props: {
        cedis:{
            type: Object,
            default: () => ({})
        }
    },
    data(){
        return {
            checked: false,
            precio: ''
        }
    },
    computed: {
        activos(){
            let contador = this.cedis.data.filter(el=>el.estado==true)
            return contador.length == 1 ? true : false
        }
    },
    methods: {
        async actualizarGamingProductoCedis(idCedis,campo,valor){
            try {


                //se vcalida la entrada de los inputs
                if(campo!=1){
                    let check = Number(valor)
                    if(!check) return this.notificacion("","Por favor escriba un valor valido","warning")
                }

                let params = {
                    id_cedis: idCedis,
                    id_gaming_producto: this.cedis.id_producto,
                    valor: valor,
                    campo: null
                }

                if(campo == 1) params.campo = 'estado'
                if(campo == 2) params.campo = 'monedas'
                if(campo == 3) params.campo = 'gemas'
                

                const { data } = await GamingProductos.actualizarGamingProductoCedis(params)
                
                if(campo==1){
                    this.$emit('aumentar',valor)
                }
                this.notificacion("","Premio actualizado correctamente","success")
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    color: #000000;
    padding-left: 12px;
}
</style>